/**
 * Utility to manage reservation statuses
 */
var modules = modules || {};
modules.manager = modules.manager || {};
modules.manager.statusmanager = function () {
    var helpers = {
        fontStyle: {
            color: '#999',
            fontSize: 12,
            margin: '10px 0 3px',
            fontFamily: 'Roboto'
        },
        labelCss: {
            color: '#999',
            fontSize: 14,
            fontWeight: 'bold',
            paddingBottom: 5,
            margin: '10px 0 3px',
            fontFamily: 'Roboto'
        },
        formInputCss: {
            color: '#555'
        },
        inputCss: {
            borderRadius: 3,
            margin: 3,
            borderColor: '#DDD',
            color: '#777'
        },
        inputBoxCss: {
            height: 36,
            width: 250,
            padding: 0,
            margin: 0,
            fontColor: '#AAA',
            paddingLeft: 10,
            border: '1px solid #DDD',
            borderRadius: 3
        },
        inputPCss: {
            color: '#555',
        },
        instructionCss: {
            paddingBottom: 10,
            fontSize: 11,
            color: '#AAA',
            fontStyle: 'italic'
        },
        newElementCss: {
            paddingTop: 5
        },
        colorPickerStyle: {
            width: '80px',
            height: '30px',
            marginTop: '6px',
            textAlign: 'center',
            borderRadius: '3px',
            cursor: 'pointer',
            borderColor: '#DCDCDC',
        },
        editStatusColorPickerStyle: {
            width: '80px',
            height: '30px',
            textAlign: 'center',
            borderRadius: '3px',
            cursor: 'pointer',
            borderColor: '#DCDCDC',
        },
        min_span_style: {
            float: 'right',
            fontSize: '18px',
            padding: '23px 55px 0px 10px',
        },
        duration_box_style: {
            height: '36px',
            width: '80px',
            margin: '6px 0 0 50px',
            textAlign: 'right',
            color: '#000',
            border: '1px solid #DDD',
            borderRadius: '3px',
            float: 'right',
            fontSize: '120%',
            backgroundColor: '#FFF',
        },

        editServiceStatusSwitch : function(statusIdentifier, enabled) {
            return $.ajax({
                url: '/api-yoa/service_status/edit',
                type: 'post',
                data: {
                    venue: helpers.getVenueKey(),
                    db_name: statusIdentifier,
                    enable: enabled
                }
            });

        },

        editServiceStatusColor : function(statusIdentifier, color) {
            return $.ajax({
                url: '/api-yoa/service_status/edit',
                type: 'post',
                data: {
                    venue: helpers.getVenueKey(),
                    db_name: statusIdentifier,
                    color: color.substring(1), //Remove the '#'
                }
            });
        },

        timerChangeHandler: function (event) {
            var self = this;
            var statusIdentifier = $(self.getDOMNode()).closest('.list-row').find('input[name=db_name]').val();
            $.ajax({
                url: '/api-yoa/service_status/edit',
                type: 'post',
                data: {
                    venue: helpers.getVenueKey(),
                    db_name: statusIdentifier,
                    duration_threshold_minutes: event.target.value,
                }
            })
            .done(function(response) {
                if(response.status === 200) {
                    helpers.refresh();
                }
            })
            .fail(function(xhr) {
                Interface._alert(JSON.parse(xhr.responseText)['msg']);
            });
        },

        status: React.createClass({
            capitalize: function(s) {
                var words = s.split(' ');
                var caps = words.map(function(word) {
                    return word.charAt(0).toUpperCase() + word.slice(1);
                });
                return caps.join(' ');
            },

            convertToID: function(s) {
                return s.replace(/ /g,"_");
            },

            getInitialState: function() {
                let imageURL;
                if(this.props.data.image === "") {
                    imageURL = "";
                }
                else {
                    imageURL = Pmp.Settings.MEDIA_URL + this.props.data.image;
                }
                display_formatted = this.capitalize(this.props.data.display);
                return {
                    name: this.props.data.display,
                    id: this.convertToID(this.props.data.display),
                    enabled: this.props.data.enabled,
                    editable: this.props.data.editable,
                    display: display_formatted,
                    image: imageURL,
                    hex_color: this.props.data.color,
                    mode: 'view',
                    active: false,
                    show_popup: false,
                    edit_popup: false,
                    delete_popup: false,
                    priority_up_popup: false,
                    priority_down_popup: false,
                    is_custom: this.props.data.custom,
                    is_preservice: this.props.service_type === 'pre-service',
                    initials: helpers.determineInitials(display_formatted).substring(0, 2),
                    recent_status_order: 1,
                    db_name: this.props.data.db_name,
                    deleted: this.props.data.deleted,
                    duration_threshold_minutes: this.props.data.duration_threshold_minutes,
                }
            },

            getDefaultProps: function() {
                return {
                    data: {
                        display: 'yay',
                        enabled: true,
                        deleted: false,
                        confirmed: true,
                        editable: true,
                    }
                };
            },

            onMouseOverHandler: function () {
                if (this.state.mode !== 'add') {
                    this.setState({'active': true});
                }
            },

            onMouseOutHandler: function () {
                if (this.state.mode !== 'add') {
                    this.setState({'active': false});
                }
            },

            clickEnable: function(e) {
                var self = this;
                var statusIdentifier = $(self.getDOMNode()).closest('.list-row').find('input[name=db_name]').val();
                if (self.state.editable) {
                    helpers.editServiceStatusSwitch(statusIdentifier, !this.state.enabled)
                    .done(function(response) {
                        if (response.status === 200) {
                            self.setState({
                                name: response.data.display,
                                enabled: response.data.enabled
                            })
                        }
                    })
                    .fail(function(xhr) {
                        Interface._alert(JSON.parse(xhr.responseText)['msg']);
                    });
                }
            },

            setIconColor : function(color) {
                this.setState({ hex_color: color });
            },

            setStatusSwitch : function(enable) {
                this.setState({ enabled: enable });
            },

            changeIconColor : function(event) {
                var self = this;
                var statusIdentifier = $(self.getDOMNode()).closest('.list-row').find('input[name=db_name]').val();
                helpers.editServiceStatusColor(statusIdentifier, event.target.value)
                .done(function(response) {
                    if (response.status === 200) {
                        self.setState({
                            hex_color: response.data.color,
                        })
                    }
                })
                .fail(function(xhr) {
                    Interface._alert(JSON.parse(xhr.responseText)['msg']);
                });
            },

            onClickNevermind: function () {
                this.setState({'mode': 'view'});
            },

            onEditClick: function () {
                this.props.update_recent_status();
                this.setState({'recent_status_order': this.props.most_recent_status + 1});
                this.props.hide_new_status();
                this.setState({'mode': 'edit'});
                this.setState({'active': false});
            },

            deleteStatus : function(statusIdentifier) {
                return $.ajax({
                    url: '/api-yoa/service_status/edit',
                    type: 'post',
                    data: {
                        venue: helpers.getVenueKey(),
                        db_name: statusIdentifier,
                        delete: true,
                    }
                });
            },

            deleteStatusHandler: function () {
                var self = this;
                var statusIdentifier = $(self.getDOMNode()).closest('.list-row').find('input[name=db_name]').val();
                self.deleteStatus(statusIdentifier)
                .done(function(response) {
                    if(response.status === 200) {
                        helpers.refresh();
                    }
                })
                .fail(function(xhr) {
                    Interface._alert(JSON.parse(xhr.responseText)['msg']);
                });
            },

            changeSortOrderHandler: function (newOrder) {
                var self = this;
                var statusIdentifier = $(self.getDOMNode()).closest('.list-row').find('input[name=db_name]').val();
                $.ajax({
                    url: '/api-yoa/service_status/priority',
                    type: 'post',
                    data: {
                        venue: helpers.getVenueKey(),
                        db_name: statusIdentifier,
                        order: newOrder,
                        service_type: self.props.service_type,
                    }
                })
                .done(function(response) {
                    if(response.status === 200) {
                        helpers.refresh();
                    }
                })
                .fail(function(xhr) {
                    Interface._alert(JSON.parse(xhr.responseText)['msg']);
                });
            },

            showDeletePopup: function(e) {
                this.setState({'show_popup': true});
                e.stopPropagation();
            },

            hideDeletePopup: function(e) {
                this.setState({'show_popup': false});
                e.stopPropagation();
            },

            showEditTooltip: function(e) {
                this.setState({'edit_popup': true});
            },

            hideEditTooltip: function(e) {
                this.setState({'edit_popup': false});
            },

            showDeleteTooltip: function(e) {
                this.setState({'delete_popup': true});
            },

            hideDeleteTooltip: function(e) {
                this.setState({'delete_popup': false});
            },

            showPriorityUpTooltip: function(e) {
                this.setState({'priority_up_popup': true});
            },

            hidePriorityUpTooltip: function(e) {
                this.setState({'priority_up_popup': false});
            },

            showPriorityDownTooltip: function(e) {
                this.setState({'priority_down_popup': true});
            },

            hidePriorityDownTooltip: function(e) {
                this.setState({'priority_down_popup': false});
            },

            renderEditPopup: function() {
                return (
                    <span style={{position: 'relative',
                                             display: 'block',
                                             top: -27,
                                             left: -70,
                                             padding: 5,
                                             margin: 'auto',
                                             width: '100%',
                                             textAlign: 'center',
                                             borderRadius: 3,
                                             backgroundColor: 'grey',
                                             color: 'white'}}>
                        <span style={{display: 'inline-block', textAlign: 'center', height: 15, width: 75}}>
                            Edit settings
                        </span>
                        <span style= {{ position: 'relative',
                                        top: 25,
                                        left: -40,
                                        width: 0,
                                        height: 0,
                                        borderStyle: 'solid',
                                        borderWidth: '5px 5px 0px 5px',
                                        borderColor: 'grey transparent transparent transparent'
                                      }}>
                        </span>
                    </span>
                )
            },

            renderEditIcon : function() {
                return (
                    <span onClick={this.onEditClick}
                          onMouseOver={this.showEditTooltip}
                          onMouseOut={this.hideEditTooltip}
                          hide_new_status={this.props.hide_new_status}
                          className="edit-container"
                          style={{ margin: '6.5px 4px 0px 0px',
                                   cursor: 'pointer',
                                   float: 'left',
                                   display: 'block'}}>
                        <img width="31px" height="36px"
                             src={helpers.media_url+"images/icons/settings-button.png"}/>
                        <span style={{float:'left', 'position': 'absolute', pointerEvents: 'none'}}>
                            {this.state.edit_popup && !this.props.adding_new_status ? this.renderEditPopup() : undefined}
                        </span>
                    </span>
                )
            },

            renderDeletePopup : function() {
                return (
                    <span style={{position: 'relative',
                                  display: 'block',
                                  top: -27,
                                  left: -50,
                                  padding: 5,
                                  margin: 'auto',
                                  width: '100%',
                                  textAlign: 'center',
                                  borderRadius: 3,
                                  backgroundColor: 'grey',
                                  color: 'white'}}>
                        <span style={{display: 'inline-block', textAlign: 'center', height: 15, width: 45}}>
                            Delete
                        </span>
                        <span style={{ position: 'relative',
                                       top: 25,
                                       left: -30,
                                       width: 0,
                                       height: 0,
                                       borderStyle: 'solid',
                                       borderWidth: '5px 5px 0px 5px',
                                       borderColor: 'grey transparent transparent transparent'
                                   }}>
                        </span>
                    </span>
                )
            },

            renderConfirmDeletePopup : function() {
                return (
                    <div style={{right: 5}} className="delete-status local-popup">
                        <span className="question">
                            Delete this status?
                        </span>
                        <div className="delete-status-confirm-area">
                            <div className="link float-right">
                                <p className="button">
                                    <a className="delete-status-btn" href="javascript:void(0);"
                                       onClick={this.deleteStatusHandler}>
                                         Confirm
                                    </a>
                                </p>
                                <p className="button plain">
                                    <a href="javascript:void(0);"
                                       onClick={this.hideDeletePopup}>
                                        nevermind
                                    </a>
                                </p>
                                <div className="float-end"></div>
                            </div>
                        </div>
                        <div className="float-end"></div>
                    </div>
                )
            },

            renderDeleteIcon : function() {
                if (this.state.is_custom) {
                    return (
                        <span onClick={this.showDeletePopup}
                              onMouseOver={this.showDeleteTooltip}
                              onMouseOut={this.hideDeleteTooltip}
                              style={{ margin: '6.5px 5px 0px 0px',
                                       cursor: 'pointer',
                                       float: 'left',
                                       display: 'block'}}>
                         <img width="31px" height="36px"
                              src={helpers.media_url+"images/icons/trash-button.png"}/>
                              <span style={{float:'left', 'position': 'absolute', pointerEvents: 'none'}}>
                                  {this.state.delete_popup ? this.renderDeletePopup() : undefined}
                              </span>
                            {this.state.show_popup ? this.renderConfirmDeletePopup() : undefined }
                        </span>
                    )
                }
            },

            renderSortUpPopup : function() {
                return (
                    <span style={{position: 'relative',
                                  display: 'block',
                                  top: -27,
                                  left: -60,
                                  padding: 5,
                                  margin: 'auto',
                                  textAlign: 'center',
                                  height: 15,
                                  width: 60,
                                  borderRadius: 3,
                                  backgroundColor: 'grey',
                                  color: 'white'}}>
                        <span style={{display: 'inline-block', textAlign: 'center', height: 15, width: 60}}>
                        Priority up
                        </span>
                        <span style={{position: 'relative',
                                      top: 10,
                                      left: 5,
                                      width: 0,
                                      height: 0,
                                      borderStyle: 'solid',
                                      borderWidth: '5px 5px 0px 5px',
                                      borderColor: 'grey transparent transparent transparent'
                                      }}>
                        </span>
                    </span>
                )
            },

            renderSortUpIcon : function() {
                return (
                    <span onClick={_.partial(this.changeSortOrderHandler, -1)}
                          onMouseOver={this.showPriorityUpTooltip}
                          onMouseOut={this.hidePriorityUpTooltip}
                          className="priority-up-container"
                          style={{
                            margin: '6.5px 5px 0px 0px',
                            cursor: 'pointer',
                            float: 'left',
                            display: 'block'}}>
                        <img width="31px" height="36px"
                             src={helpers.media_url+"images/icons/priority-up-button.png"}/>
                        <span style={{float:'left', 'position': 'absolute', pointerEvents: 'none'}}>
                            {this.state.priority_up_popup ? this.renderSortUpPopup() : undefined}
                        </span>
                    </span>
                )
            },

            renderSortDownPopup : function() {
                return (
                    <span style={{position: 'relative',
                                  display: 'block',
                                  top: -27,
                                  left: -100,
                                  padding: 5,
                                  margin: 'auto',
                                  width: 80,
                                  height: 15,
                                  textAlign: 'center',
                                  borderRadius: 3,
                                  backgroundColor: 'grey',
                                  color: 'white'}}>
                        <span style={{display: 'inline-block', textAlign: 'center', height: 15, width: 75}}>
                        Priority down
                        </span>
                        <span style={{
                                      position: 'relative',
                                      top: 10,
                                      left: 30,
                                      width: 0,
                                      height: 0,
                                      borderStyle: 'solid',
                                      borderWidth: '5px 5px 0px 5px',
                                      borderColor: 'grey transparent transparent transparent'
                                      }}>
                        </span>
                    </span>
                )
            },

            renderSortDownIcon : function() {
                return (
                    <span onClick={_.partial(this.changeSortOrderHandler, 1)}
                          onMouseOver={this.showPriorityDownTooltip}
                          onMouseOut={this.hidePriorityDownTooltip}
                          className="priority-do-container"
                          style={{ margin: '6.5px 25px 0px 0px',
                                   cursor: 'pointer',
                                   float: 'left',
                                   display: 'block'}}>
                        <img width="31px" height="36px"
                             src={helpers.media_url+"images/icons/priority-down-button.png"}/>
                        <span style={{float:'left', 'position': 'absolute', pointerEvents: 'none'}}>
                            {this.state.priority_down_popup ? this.renderSortDownPopup() : undefined}
                        </span>
                    </span>
                )
            },

            render: function() {
                var colorpicker_class = 'padding-check';
                var switch_type = 'switch';
                if (this.state.editable) {
                    switch_type += (this.state.enabled) ? ' on':' off';
                    colorpicker_class = 'padding-' + switch_type;
                } else if (this.state.enabled) {
                    switch_type += ' check';
                } else {
                    return null;
                }
                var bg_color = (helpers.colorBtwnHex(this.state.hex_color, "FAFAFA", "FFFFFF")) ? "#FAFAFA" : this.state.hex_color;
                var icon_style = {
                    backgroundColor: bg_color,
                    backgroundSize: '32px 32px',
                    width: '32px',
                    height: '32px',
                    textAlign: 'center',
                    borderRadius: '50%',
                    color: '#FFFFFF',
                    lineHeight: '32.5px',
                }
                var icon_initials = this.state.initials;
                if (this.state.image !== "") {
                    icon_style.backgroundImage = 'url("' + (this.state.image) + '")';
                    icon_initials = "";
                }
                var editOptions = (
                    <span className="float-right">
                        {this.renderEditIcon()}
                        {this.renderDeleteIcon()}
                        {this.renderSortUpIcon()}
                        {this.renderSortDownIcon()}
                    </span>
                );
                if (this.state.mode === 'edit' && !this.props.adding_new_status && this.state.recent_status_order === this.props.most_recent_status) {
                    return (<helpers.editStatus
                        onClickNevermind={this.onClickNevermind}
                        new_status={false}
                        setIconColor={this.setIconColor}
                        setStatusSwitch={this.setStatusSwitch}
                        status={this.state}/>);
                } else if (!this.state.deleted) {
                    return (
                        <div className='list-row'
                            onMouseEnter={this.onMouseOverHandler}
                            onMouseLeave={this.onMouseOutHandler}>
                            <input name='db_name' type='hidden' value={this.state.db_name}/>
                            <div className='row-title'>
                                <span className='row-status-icon' style={icon_style}>{icon_initials}</span>
                                <span className='row-title-display'>{this.state.display}</span>
                            </div>
                            {!this.state.is_preservice && (
                            <div className="timer-wrapper">
                                <span style={helpers.min_span_style}>min</span>
                                <input type="number"
                                   name="duration_threshold_minutes"
                                   style={helpers.duration_box_style}
                                   className="duration-threshold-minutes"
                                   defaultValue={this.state.duration_threshold_minutes}
                                   onBlur={helpers.timerChangeHandler}
                                   onChange={helpers.timerChangeHandler}/>
                            </div>)}
                            <div className="switch-wrapper">
                                <div className={switch_type} onClick={this.clickEnable}></div>
                            </div>
                            <div className={colorpicker_class}>
                                <Common.ColorPicker value={this.state.hex_color} name="icon_color" onChangeHandler={this.changeIconColor} inputStyle={helpers.colorPickerStyle}/>
                            </div>
                            {this.state.active && this.state.mode !== 'add'
                                ? (editOptions) : undefined}
                            <div className='float-end'></div>
                        </div>
                    );
                } else {
                    return (
                        <div></div>
                        );
                }
            }
        }),

        statusName: React.createClass({
            onButtonPress: function () {
                firstChars = helpers.determineInitials($('input[name=status_name]').val());
                initials = firstChars.substring(0, 2);
                $('input[name=icon_initials]').val(initials);
            },

            render: function () {
                var status_name_style, input_box_style;
                if (this.props.new_status) {
                    status_name_style = helpers.labelCss;
                    input_box_style = helpers.inputBoxCss;
                } else {
                    status_name_style = {
                        color: '#999',
                        fontSize: 14,
                        fontWeight: 'bold',
                        paddingBottom: 5,
                        margin: '7px 0 3px 10px',
                        fontFamily: 'Roboto'
                    };
                    input_box_style = {
                        height: 36,
                        width: 250,
                        padding: 0,
                        marginLeft: '10px',
                        fontColor: '#AAA',
                        paddingLeft: 10,
                        border: '1px solid #DDD',
                        borderRadius: 3
                    };
                }
                return (
                    <div className="input-area input-col float-left">
                        <p className="label" style={status_name_style}>Status name</p>
                        <p className="input">
                            <input type="text"
                                   name="status_name"
                                   onChange={this.onButtonPress}
                                   style={_.extend({},
                                            input_box_style,
                                            {fontSize: '120%',
                                            backgroundColor: '#FFF',
                                            color: '#AAA'})}
                                   className="status-name"
                                   defaultValue={this.props.name}/>
                            {!this.props.new_status ?
                                <input type='hidden' name="original_status_name" defaultValue={this.props.name}/>
                            : undefined}
                        </p>
                    </div>
                );
            }
        }),

        iconInitials: React.createClass({
            render: function () {
                return (
                    <div className="input-area input-col float-left">
                        <p className="label" style={_.extend({}, helpers.labelCss, {marginLeft: '10px'})}>Icon initials</p>
                        <p className="input">
                            <input type="text"
                                   name="icon_initials"
                                   disabled={true}
                                   style={_.extend({},
                                            helpers.inputBoxCss,
                                            {fontSize: '120%',
                                            backgroundColor: '#EEE',
                                            width: '80px !important',
                                            marginLeft: '10px',
                                            color: '#AAA'})}
                                   className="icon-initials"
                                   defaultValue={this.props.initials}/>
                            {!this.props.new_status ?
                                <input type='hidden' name="initial" defaultValue={this.props.initials}/>
                            : undefined}
                        </p>
                    </div>
                );
            }
        }),

        statusClassification: React.createClass({
            render: function () {
                return (
                    (<div className="form-element">
                        <p className="input" style={helpers.inputPCss}>
                            <label>
                                <input type="radio"
                                     name="status_classification"
                                     value="pre"
                                     onClick={this.props.onToggleClassification}
                                     defaultChecked={this.props.is_preservice}
                                     style={_.extend({}, helpers.inputCss,
                                          {marginRight: 5}
                                     )}
                                />
                                Pre-Service Status
                            </label>
                            <label>
                                <input type="radio"
                                   name="status_classification"
                                   value="in"
                                   onClick={this.props.onToggleClassification}
                                   defaultChecked={!this.props.is_preservice}
                                   style={_.extend({}, helpers.inputCss,
                                        {marginRight: 5,
                                         marginLeft: 10
                                        }
                                   )}
                                />
                                In-Service Status
                            </label>
                        </p>
                    </div>)
                );
            }
        }),

        durationThresholdMinutes: React.createClass({
            render: function () {
                var label_style = {
                    color: '#999',
                    fontSize: 14,
                    fontWeight: 'bold',
                    paddingBottom: 5,
                    margin: '10px 0 3px 10px',
                    fontFamily: 'Roboto'
                };

                var input_box_style = {
                    height: 36,
                    width: 250,
                    padding: 0,
                    marginLeft: '10px',
                    fontColor: '#AAA',
                    paddingLeft: 10,
                    border: '1px solid #DDD',
                    borderRadius: 3
                };

                return (
                    <div className="duration-threshold-minutes-div input-area input-col float-left">
                        <p className="label" style={label_style}>Duration threshold (in minutes)</p>
                        <p className="input">
                            <input type="number"
                               name="duration_threshold_minutes"
                               style={_.extend({},
                                        input_box_style,
                                        {fontSize: '120%',
                                        backgroundColor: '#FFF',
                                        color: '#AAA'})}
                               className="duration-threshold-minutes"
                               defaultValue={this.props.duration_threshold_minutes}/>
                        </p>
                    </div>
                );
            }
        }),

        editStatus: React.createClass({
            addNewStatus : function(statusName, iconColor, statusClassification, durationThresholdMinutes) {
                var self = this;

                var dataDict = {
                    venue: helpers.getVenueKey(),
                    name: statusName,
                    color: iconColor,
                    service: statusClassification,
                    confirm: self.props.status.is_preservice ? false : true,
                    enable: true,
                    dead: false,
                    delete: false,
                }

                if (durationThresholdMinutes !== null) {
                    dataDict["duration_threshold_minutes"] = durationThresholdMinutes
                }

                return $.ajax({
                    url: '/api-yoa/service_status/add',
                    type: 'post',
                    data: dataDict
                });
            },

            changeIconColor : function(event) {
                var self = this;
                var statusIdentifier = $(self.getDOMNode()).find('input[name=status_identifier]').val();
                helpers.editServiceStatusColor(statusIdentifier, event.target.value)
                .done(function(response) {
                    if (response.status === 200) {
                        self.props.setIconColor(response.data.color);
                    }
                })
                .fail(function(xhr) {
                    Interface._alert(JSON.parse(xhr.responseText)['msg']);
                });
            },

            clickEnable: function(e) {
                var self = this;
                var statusIdentifier = $(self.getDOMNode()).find('input[name=status_identifier]').val();
                if (self.props.status.editable) {
                    helpers.editServiceStatusSwitch(statusIdentifier, !self.props.status.enabled)
                    .done(function(response) {
                        if (response.status === 200) {
                            self.props.setStatusSwitch(response.data.enabled);
                        }
                    })
                    .fail(function(xhr) {
                        Interface._alert(JSON.parse(xhr.responseText)['msg']);
                    });
                }
            },

            updateStatus : function(oldStatusName, newStatusName, statusIdentifier) {
                dataDict = {
                    venue: helpers.getVenueKey(),
                    status: oldStatusName,
                    new_name: newStatusName,
                    db_name: statusIdentifier,
                }

                return $.ajax({
                    url: '/api-yoa/service_status/edit',
                    type: 'post',
                    data: dataDict
                });
            },

            onSaveHandler : function () {
                var self = this;
                var statusName = $('input[name=status_name]').val().trim();
                var iconInitials = $('input[name=icon_initials]').val();
                var iconColor = $('input[name=icon_color]').val();
                var statusClassification = $('input[name=status_classification]:checked').val();
                var oldStatusName = $('input[name=original_status_name]').val();
                var statusIdentifier = $('input[name=status_identifier]').val();
                var durationThresholdMinutes = (is_preservice) ? null : $('input[name=duration_threshold_minutes]').val();

                var is_preservice =
                    (self.props.hasOwnProperty('status') && self.props.status.hasOwnProperty('is_preservice')) ?
                        self.props.status.is_preservice : (statusClassification === "pre");

                if (!iconColor) {
                    Interface._alert('Status must have a color');
                    return;
                }

                if (!statusName) {
                    Interface._alert('Status must have a name');
                    return;
                }

                if (self.props.new_status) {
                    self.addNewStatus(statusName, iconColor, statusClassification, durationThresholdMinutes)
                    .done(function(response) {
                        if(response.status === 200) {
                            helpers.refresh();
                        }
                    })
                    .fail(function(xhr) {
                        Interface._alert(JSON.parse(xhr.responseText)['msg']);
                    });
                } else {
                    self.updateStatus(oldStatusName, statusName, statusIdentifier)
                    .done(function(response) {
                        if(response.status === 200) {
                            helpers.refresh();
                        }
                    })
                    .fail(function(xhr) {
                        Interface._alert(JSON.parse(xhr.responseText)['msg']);
                    });
                }
            },

            restoreStatus : function(statusIdentifier) {
                return $.ajax({
                    url: '/api-yoa/service_status/edit',
                    type: 'post',
                    data: {
                        venue: helpers.getVenueKey(),
                        db_name: statusIdentifier,
                        restore_status: true,
                    }
                });
            },

            onRestoreHandler : function() {
                var self = this;
                var statusIdentifier = $('input[name=status_identifier]').val();
                self.restoreStatus(statusIdentifier)
                .done(function(response) {
                    if(response.status === 200) {
                        helpers.refresh();
                    }
                })
                .fail(function(xhr) {
                    Interface._alert(JSON.parse(xhr.responseText)['msg']);
                });
            },

            onClickHandler: function () {
                $('#colorpicker').hide();
            },

            render: function () {
                var colorVal = (this.props.status.hex_color) ? this.props.status.hex_color : '#FFFFFF';
                var colorpicker_class = '';
                var switch_type = '';
                var row_style;
                var save_style = {
                    float: 'left',
                }
                var is_preservice =
                    (this.props.hasOwnProperty('status') && this.props.status.hasOwnProperty('is_preservice')) ?
                        this.props.status.is_preservice : false;
                if(!this.props.new_status) {
                    colorpicker_class = 'padding-check'
                    switch_type = 'switch';
                    if (this.props.status.editable) {
                        switch_type += (this.props.status.enabled) ? ' on':' off';
                        colorpicker_class = 'padding-' + switch_type;
                    } else if (this.props.status.enabled) {
                        switch_type += ' check';
                    }
                    var bg_color = (helpers.colorBtwnHex(this.props.status.hex_color, "FAFAFA", "FFFFFF")) ? "#FAFAFA" : this.props.status.hex_color;
                    var icon_style = {
                        backgroundColor: bg_color,
                        backgroundSize: '32px 32px',
                        width: '32px',
                        height: '32px',
                        textAlign: 'center',
                        borderRadius: '50%',
                        color: '#FFFFFF',
                        lineHeight: '32.5px',
                    }
                    var icon_initials = this.props.status.initials;
                    if (this.props.status.image !== "") {
                        icon_style.backgroundImage = 'url("' + (this.props.status.image) + '")';
                        icon_initials = "";
                    }
                    row_style = _.extend({}, helpers.fontStyle,
                        {padding: '5px 0px 15px 7px',
                        overflow: 'hidden',
                    })
                    save_style.marginLeft = '41px'
                } else {
                    row_style = _.extend({}, helpers.fontStyle,
                        {padding: '0px 25px 15px 25px',
                        overflow: 'hidden'
                    })
                }
                return (
                    <div className="list-row" style={{borderBottom: '1px solid #EEE'}}>
                        <input name="db_name" type="hidden" value={this.props.status.db_name}/>
                        <div className="new_status"
                            onClick={this.onClickHandler}
                            style={row_style}>
                            <div style={{clear: 'both'}}>
                                <input name='status_identifier' type='hidden' value={(this.props.status || {}).db_name}></input>
                                <div style={icon_style} className="input-area input-col float-left">
                                    <span className='row-status-icon'>{icon_initials}</span>
                                </div>
                                <helpers.statusName
                                    new_status={this.props.new_status}
                                    name={(this.props.status || {}).display}
                                    is_default={(this.props.status || {}).is_default}/>
                                {!((this.props.status || {}).is_preservice) ? (
                                    <helpers.durationThresholdMinutes
                                    new_status={this.props.new_status}
                                    duration_threshold_minutes={this.props.status.duration_threshold_minutes}/>
                                ) : undefined }
                                {this.props.status.is_custom || this.props.new_status ? (
                                <helpers.iconInitials
                                    initials={(this.props.status || {}).initials}
                                    new_status={this.props.new_status}
                                    is_default={(this.props.status || {}).is_default}/> )
                                    : undefined }
                                {this.props.new_status
                                ? (
                                    <div className="input-area input-col float-left" style={{ paddingLeft: 10 }}>
                                        <p className="label" style={_.extend({}, helpers.labelCss, {marginLeft: '10px'})}>Color</p>
                                        <Common.ColorPicker value={colorVal} name="icon_color" onChangeHandler={helpers.status.changeIconColor} inputStyle={helpers.editStatusColorPickerStyle}/>
                                    </div>
                                ) : undefined }
                                {!this.props.new_status
                                ? (
                                <div className="float-right-wrapper">
                                    {!is_preservice && (
                                    <div className="timer-wrapper">
                                        <span style={helpers.min_span_style}>min</span>
                                        <input type="number"
                                           name="duration_threshold_minutes"
                                           style={_.extend({}, helpers.duration_box_style, {marginTop: '0px !important'})}
                                           className="duration-threshold-minutes"
                                           defaultValue={this.props.status.duration_threshold_minutes}
                                           onBlur={helpers.timerChangeHandler}
                                           onChange={helpers.timerChangeHandler}/>
                                    </div>)}
                                    <div className="switch-wrapper" style={{ display: 'inline-block', float: 'right' }}>
                                        <div className={switch_type} onClick={this.clickEnable} style={{ marginTop: '5px !important' }}></div>
                                    </div>
                                    <div className={colorpicker_class} style={{ float: 'right' }}>
                                        <Common.ColorPicker value={this.props.status.hex_color} name="icon_color" onChangeHandler={this.changeIconColor} inputStyle={_.extend({}, helpers.colorPickerStyle, {marginTop: '0px !important'})}/>
                                    </div>
                                </div>
                                ) : undefined }
                            </div>
                            {this.props.new_status ? (
                            <div style={{clear: 'both'}}>
                                <p className="label" style={_.extend({}, {paddingTop: 20}, helpers.labelCss)}>
                                    Classification
                                </p>
                                <helpers.statusClassification
                                    status_id={(this.props.status || {}).id}
                                    is_preservice={(this.props.status || {}).is_preservice}/>
                            </div>
                            ) : undefined}
                            <div style={{clear: 'both'}}>
                                <div style={{clear: 'both', padding: '15px 0px'}}>
                                    <p className="button" style={save_style}>
                                        <a onClick={this.onSaveHandler}
                                        href="javascript:void(0)" className="save-status-btn">Save</a>
                                    </p>
                                    {!this.props.status.is_custom && !this.props.new_status
                                    ? (
                                        <p className="button" style={{float: 'left', marginLeft: '15px'}}>
                                            <a onClick={this.onRestoreHandler}
                                            href="javascript:void(0)" className="reset-status-btn">Restore Defaults</a>
                                        </p>
                                    ) : undefined }
                                    <a style={{float: 'left', lineHeight: '40px', paddingLeft: 20}}
                                       href="javascript:void(0)"
                                       onClick={this.props.onClickNevermind}
                                       className="cancel-status-btn">Nevermind</a>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        }),

        allStatusesView: React.createClass({
            getInitialState: function () {
                return {
                    new_status: false,
                    pre_service: this.props.preservice_res_statuses,
                    in_service: this.props.inservice_res_statuses,
                    most_recent_status: 0,
                };
            },

            updateRecentStatus: function () {
                this.setState({most_recent_status: this.state.most_recent_status + 1});
            },

            onClickHandler: function () {
                this.setState({new_status: true});
            },

            onClickNevermind: function () {
                this.setState({new_status: false});
            },

            render: function () {
                /*ignore jslint start*/
                var pre_service = this.state.pre_service.map(function(s){
                    return <helpers.status service_type='pre-service'
                            data={s}
                            adding_new_status={this.state.new_status}
                            hide_new_status={this.onClickNevermind}
                            most_recent_status={this.state.most_recent_status}
                            update_recent_status={this.updateRecentStatus} />;
                }, this);
                var in_service = this.state.in_service.map(function(s){
                    return <helpers.status service_type='in-service'
                            data={s}
                            adding_new_status={this.state.new_status}
                            hide_new_status={this.onClickNevermind}
                            most_recent_status={this.state.most_recent_status}
                            update_recent_status={this.updateRecentStatus} />;
                }, this);
                return (
                    <div style={{marginBottom: 20}}>
                        <div className="header"
                             style={{borderBottom: '1px solid #e1e1e1', height: 40, padding: 10, overflow: 'hidden'}}>
                            <span className="float-left" style={{fontSize: 20, fontFamily: 'Oswald'}}>RESERVATION STATUSES</span>
                            <span className="float-right">
                                <p className="button" style={{float: 'left'}}>
                                    <a onClick={this.onClickHandler} href="javascript:void(0)">Add Status</a>
                                </p>
                            </span>
                        </div>
                        <span>
                            <div>
                            {this.state.new_status ?
                                <helpers.editStatus
                                    onClickNevermind={this.onClickNevermind}
                                    new_status={true}
                                    status={{}}
                                /> : undefined}
                            </div>
                            <div>
                                <div className="list">
                                    <div className='list-title'>
                                        <span>Pre-Service Statuses</span>
                                        <span style={{float: 'right', paddingRight: '28px'}}>Active</span>
                                        <span style={{float: 'right', paddingRight: '60px'}}>Color</span>
                                    </div>
                                    {pre_service}
                                </div>
                                <div className="list">
                                    <div className='list-title'>
                                        <span>In-Service Statuses</span>
                                        <span style={{float: 'right', paddingRight: '28px'}}>Status Timer Threshold</span>
                                        <span style={{float: 'right', paddingRight: '35px'}}>Active</span>
                                        <span style={{float: 'right', paddingRight: '60px'}}>Color</span>
                                    </div>
                                    {in_service}
                                </div>
                            </div>
                        </span>
                        <div className="res-status-caption">
                        Note: Changes will appear in mobile apps within 6 hours. To reflect changes immediately, logout of your iOS app and log back in again.
                        </div>
                    </div>
                    );
            }
        }),

        init: function (div_id, media_url, venue_name, venue_group_id) {
            helpers.media_url      = media_url;
            helpers.venue_name     = venue_name;
            helpers.venue_group_id = venue_group_id;
            helpers.id             = div_id;
            helpers.fetch_url      = '/api-yoa/service_status';
            helpers.initLoad();
        },

        initLoad: function() {
            $.get('/manager/' + helpers.venue_name + '/data/bookedbynames', function(response) {
                helpers.booked_by_users = _.filter(response.payload.content.booked_by_users, function(u) {
                    return u.is_user;
                });
                helpers.refresh();
            }).fail(function() {
                Interface._alert("Something went wrong, please try later!");
            });
        },

        getVenueKey: function() {
            return document.location.pathname.split('/')[2];
        },

        determineInitials: function (status_name) {
            return status_name.replace(/\W*(\w)\w*/g, '$1').toUpperCase();
        },

        determineNumBtwnVals: function(num, smallNum, bigNum) {
            return (smallNum <= num && num <= bigNum)
        },

        retrieveHexVal: function (hexColor) {
            if (!hexColor) {
                return [255, 255, 255];
            }
            var r = parseInt(hexColor.substring(0,2), 16);
            var g = parseInt(hexColor.substring(2,4), 16);
            var b = parseInt(hexColor.substring(4), 16);
            return [r, g, b];
        },

        colorBtwnHex: function (color, hex1, hex2) {
            var colorHexVals = helpers.retrieveHexVal(color.split('#')[1]);
            var hex1Vals = helpers.retrieveHexVal(hex1);
            var hex2Vals = helpers.retrieveHexVal(hex2);

            for (var i = 0; i <= 2; i++) {
                if (!helpers.determineNumBtwnVals(colorHexVals[i], hex1Vals[i], hex2Vals[i])) {
                    return false;
                }
            }
            return true;
        },

        refresh: function () {
            $.ajax({
                url: '/api-yoa/service_status',
                type: 'get',
                data: {
                    venue: helpers.getVenueKey()
                }
            }).done(function(response) {
                if(response.status === 200) {
                    React.render(
                        <helpers.allStatusesView
                            key={new Date().getTime()}
                            preservice_res_statuses={response.data['pre-service']}
                            inservice_res_statuses={response.data['in-service']}
                        />, document.getElementById(helpers.id)
                    );
                }
            }).fail(function() {
                Interface._alert("Something went wrong, please try later!");
            });
        }
    };

    return {
        init: helpers.init
    };
}();
